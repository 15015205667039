
































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { copyToClipboard, Notify } from "quasar";

@Component({
  components: {}
})
export default class Publicacion extends Vue {
  private nombreWeb: any = "";
  private dataNoticia: any = "";
  private dataNoticiasRelacionadas: any = [];

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoNoticia();
    }
  }

  private getInfoNoticia() {
    this.$axios
      .get("noticias/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.dataNoticia = res.data;
        this.dataNoticiasRelacionadas = this.dataNoticia.relacionadas;
        this.visitaNoticia();
        if (this.dataNoticia.fotos.image_fulltext) {
          this.dataNoticia.fotos.image_fulltext =
            base + this.dataNoticia.fotos.image_fulltext;
        } else {
          this.dataNoticia.fotos.image_fulltext = require("@/assets/logos/logo1200x360.png");
        }
        if (this.dataNoticia.fotos.image_intro) {
          this.dataNoticia.fotos.image_intro =
            base + this.dataNoticia.fotos.image_intro;
        } else {
          this.dataNoticia.fotos.image_intro = require("@/assets/logos/logo300.png");
        }

        for (let i = 0; i < this.dataNoticiasRelacionadas.length; i++) {
          if (
            this.dataNoticiasRelacionadas[i].fotos.image_intro &&
            this.dataNoticiasRelacionadas[i].fotos.image_intro != ""
          ) {
            this.dataNoticiasRelacionadas[i].fotos.image_intro =
              base + this.dataNoticiasRelacionadas[i].fotos.image_intro;
          } else {
            this.dataNoticiasRelacionadas[
              i
            ].fotos.image_intro = require("@/assets/logos/logo300.png");
          }
        }

        //MetaData generica
        document.title =
          this.dataNoticia.titulo +
          " - Ilustre Municipalidad de Juan Fernández";
        (document.querySelector(
          'meta[name="description"]'
        ) as any).setAttribute("content", this.dataNoticia.descripcion_corta);
        (document.querySelector('meta[name="image"]') as any).setAttribute(
          "content",
          this.dataNoticia.fotos.image_intro
        );

        //MetaData Twitter
        (document.querySelector(
          'meta[name="twitter:title"]'
        ) as any).setAttribute(
          "content",
          this.dataNoticia.titulo + " - Ilustre Municipalidad de Juan Fernández"
        );
        (document.querySelector(
          'meta[name="twitter:description"]'
        ) as any).setAttribute("content", this.dataNoticia.descripcion_corta);
        (document.querySelector(
          'meta[name="twitter:image:src"]'
        ) as any).setAttribute("content", this.dataNoticia.fotos.image_intro);

        //MetaData Facebook
        (document.querySelector(
          'meta[property="og:title"]'
        ) as any).setAttribute(
          "content",
          this.dataNoticia.titulo + " - Ilustre Municipalidad de Juan Fernández"
        );
        (document.querySelector(
          'meta[property="og:description"]'
        ) as any).setAttribute("content", this.dataNoticia.descripcion_corta);
        (document.querySelector(
          'meta[property="og:image"]'
        ) as any).setAttribute("content", this.dataNoticia.fotos.image_intro);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private visitaNoticia() {
    this.$axios
      .put("noticias/visita", {
        id: this.dataNoticia.id
      })
      .then((res: any) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);
    const hora = fechaInicio.substring(11, 16);
    if (mesInicio == 1) {
      mesInicio = "ene";
    } else if (mesInicio == 2) {
      mesInicio = "feb";
    } else if (mesInicio == 3) {
      mesInicio = "mar";
    } else if (mesInicio == 4) {
      mesInicio = "abr";
    } else if (mesInicio == 5) {
      mesInicio = "may";
    } else if (mesInicio == 6) {
      mesInicio = "jun";
    } else if (mesInicio == 7) {
      mesInicio = "jul";
    } else if (mesInicio == 8) {
      mesInicio = "ago";
    } else if (mesInicio == 9) {
      mesInicio = "sep";
    } else if (mesInicio == 10) {
      mesInicio = "oct";
    } else if (mesInicio == 11) {
      mesInicio = "nov";
    } else {
      mesInicio = "dic";
    }

    if (mesInicio != 0) {
      return (
        "Publicado el " + diaInicio + " de " + mesInicio + ", " + anhoInicio
      );
    }
  }
  private copiarClipboard() {
    copyToClipboard(window.location.href)
      .then(() => {
        Notify.create({
          color: "positive",
          message: "Se ha copiado en el Link correctamente.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      })
      .catch(() => {
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema. Por favor, inténtelo de nuevo.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }
  private irLink() {
    var link =
      "https://web.whatsapp.com/send?l=en&text=" +
      encodeURIComponent(window.location.href);
    window.open(link);
  }

  private irLinkMobile() {
    var link =
      "https://api.whatsapp.com/send?text=" +
      encodeURIComponent(window.location.href);
    window.open(link);
  }

  private irEmail(titulo: string, contenido: string) {
    window.open(
      "mailto:?Subject=" +
        titulo +
        "&" +
        "Body=" +
        encodeURIComponent(window.location.href)
    );
  }

  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      if (
        this.$router.currentRoute.name == "Publicacion" &&
        this.nombreWeb != nombreWeb
      ) {
        this.$router
          .push({
            name: "Publicacion",
            params: { nombre: nombreWeb }
          })
          .catch(err => {
            console.log(err);
          });
        this.$router.go(0);
      } else {
        this.$router
          .push({
            name: "Publicacion",
            params: { nombre: nombreWeb }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }
  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private fechaPublicacionNoticias(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);
    const hora = fechaInicio.substring(11, 16);

    if (mesInicio == 1) {
      mesInicio = "ene";
    } else if (mesInicio == 2) {
      mesInicio = "feb";
    } else if (mesInicio == 3) {
      mesInicio = "mar";
    } else if (mesInicio == 4) {
      mesInicio = "abr";
    } else if (mesInicio == 5) {
      mesInicio = "may";
    } else if (mesInicio == 6) {
      mesInicio = "jun";
    } else if (mesInicio == 7) {
      mesInicio = "jul";
    } else if (mesInicio == 8) {
      mesInicio = "ago";
    } else if (mesInicio == 9) {
      mesInicio = "sep";
    } else if (mesInicio == 10) {
      mesInicio = "oct";
    } else if (mesInicio == 11) {
      mesInicio = "nov";
    } else {
      mesInicio = "dic";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + ", " + anhoInicio;
    }
  }
}
