














































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { copyToClipboard, Notify } from "quasar";

@Component({
  components: {},
})
export default class Publicacion extends Vue {
  private nombreWeb: any = "";
  private horizontalPercentageComercios = 0;
  private bucket: any = "";

  private dataActividad: any = "";

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoActividad();
      this.autoscrollComercios();
    }
  }

  private scrollAreaFunctionComercios(info: any) {
    this.horizontalPercentageComercios = info.horizontalPercentage;
  }

  private scroll(direccion: string) {
    if (this.$refs.scrollComercios as any) {
      // console.log("horisozxntal", info.horizontalPercentage);

      const size = (this.$refs.scrollComercios as any).scrollSize;
      const position = (this.$refs.scrollComercios as any).scrollPosition;
      if (direccion == "derecha") {
        if (this.horizontalPercentageComercios >= 1) {
          (this.$refs.scrollComercios as any).setScrollPosition(0, 120);
        } else {
          (this.$refs.scrollComercios as any).setScrollPosition(
            position + 250,
            120
          );
        }
      } else {
        if (position - 90 < 0) {
          if (position > 0) {
            (this.$refs.scrollComercios as any).setScrollPosition(0, 120);
          } else {
            (this.$refs.scrollComercios as any).setScrollPosition(size, 120);
          }
        } else {
          (this.$refs.scrollComercios as any).setScrollPosition(
            position - 90,
            120
          );
        }
      }
    }
  }
  private autoscrollComercios() {
    this.scroll("derecha");
    setTimeout(this.autoscrollComercios, 5000);
  }
  private getInfoActividad() {
    this.$axios
      .get("actividades/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.dataActividad = res.data;
        this.visitaActividad();
        if (this.dataActividad.foto && this.dataActividad.foto != "") {
          this.dataActividad.foto = base + this.dataActividad.foto;
        } else {
          this.dataActividad.foto = require("@/assets/logos/logo1200x360.png");
        }

        if (this.dataActividad.relacionadas) {
          for (let i = 0; i < this.dataActividad.relacionadas.length; i++) {
            if (
              this.dataActividad.relacionadas[i].foto &&
              this.dataActividad.relacionadas[i].foto != ""
            ) {
              this.dataActividad.relacionadas[i].foto =
                base + this.dataActividad.relacionadas[i].foto;
            } else {
              this.dataActividad.relacionadas[
                i
              ].foto = require("@/assets/logos/logo300.png");
            }
          }
        }

        //MetaData generica
        document.title =
          this.dataActividad.nombre +
          " - Ilustre Municipalidad de Juan Fernández";
        (document.querySelector(
          'meta[name="description"]'
        ) as any).setAttribute("content", this.dataActividad.descripcion);
        (document.querySelector('meta[name="image"]') as any).setAttribute(
          "content",
          this.dataActividad.foto
        );

        //MetaData Twitter
        (document.querySelector(
          'meta[name="twitter:title"]'
        ) as any).setAttribute(
          "content",
          this.dataActividad.nombre +
            " - Ilustre Municipalidad de Juan Fernández"
        );
        (document.querySelector(
          'meta[name="twitter:description"]'
        ) as any).setAttribute("content", this.dataActividad.descripcion);
        (document.querySelector(
          'meta[name="twitter:image:src"]'
        ) as any).setAttribute("content", this.dataActividad.foto);

        //MetaData Facebook
        (document.querySelector(
          'meta[property="og:title"]'
        ) as any).setAttribute(
          "content",
          this.dataActividad.nombre +
            " - Ilustre Municipalidad de Juan Fernández"
        );
        (document.querySelector(
          'meta[property="og:description"]'
        ) as any).setAttribute("content", this.dataActividad.descripcion);
        (document.querySelector(
          'meta[property="og:image"]'
        ) as any).setAttribute("content", this.dataActividad.foto);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private visitaActividad() {
    this.$axios
      .put("actividades/visita", {
        id: this.dataActividad.id,
      })
      .then((res: any) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);
    const hora = fechaInicio.substring(11, 16);

    if (mesInicio == 1) {
      mesInicio = "enero";
    } else if (mesInicio == 2) {
      mesInicio = "febrero";
    } else if (mesInicio == 3) {
      mesInicio = "marzo";
    } else if (mesInicio == 4) {
      mesInicio = "abril";
    } else if (mesInicio == 5) {
      mesInicio = "mayo";
    } else if (mesInicio == 6) {
      mesInicio = "junio";
    } else if (mesInicio == 7) {
      mesInicio = "julio";
    } else if (mesInicio == 8) {
      mesInicio = "agosto";
    } else if (mesInicio == 9) {
      mesInicio = "septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "octubre";
    } else if (mesInicio == 11) {
      mesInicio = "noviembre";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return (
        diaInicio + " de " + mesInicio + " " + anhoInicio + " a las " + hora
      );
    }
  }

  private fechasEvento(fecha_inicio, fecha_termino) {
    var anho_inicio = fecha_inicio.substring(0, 4);
    var mes_inicio: any = parseInt(fecha_inicio.substring(5, 7), 10);
    var dia_inicio = fecha_inicio.substring(8, 10);

    var anho_termino = fecha_termino.substring(0, 4);
    var mes_termino: any = parseInt(fecha_termino.substring(5, 7), 10);
    var dia_termino = fecha_termino.substring(8, 10);

    if (mes_inicio == 1) {
      mes_inicio = "Enero";
    } else if (mes_inicio == 2) {
      mes_inicio = "Febrero";
    } else if (mes_inicio == 3) {
      mes_inicio = "Marzo";
    } else if (mes_inicio == 4) {
      mes_inicio = "Abril";
    } else if (mes_inicio == 5) {
      mes_inicio = "Mayo";
    } else if (mes_inicio == 6) {
      mes_inicio = "Junio";
    } else if (mes_inicio == 7) {
      mes_inicio = "Julio";
    } else if (mes_inicio == 8) {
      mes_inicio = "Agosto";
    } else if (mes_inicio == 9) {
      mes_inicio = "Septiembre";
    } else if (mes_inicio == 10) {
      mes_inicio = "Octubre";
    } else if (mes_inicio == 11) {
      mes_inicio = "Noviembre";
    } else {
      mes_inicio = "Diciembre";
    }
    if (mes_termino == 1) {
      mes_termino = "Enero";
    } else if (mes_termino == 2) {
      mes_termino = "Febrero";
    } else if (mes_termino == 3) {
      mes_termino = "Marzo";
    } else if (mes_termino == 4) {
      mes_termino = "Abril";
    } else if (mes_termino == 5) {
      mes_termino = "Mayo";
    } else if (mes_termino == 6) {
      mes_termino = "Junio";
    } else if (mes_termino == 7) {
      mes_termino = "Julio";
    } else if (mes_termino == 8) {
      mes_termino = "Agosto";
    } else if (mes_termino == 9) {
      mes_termino = "Septiembre";
    } else if (mes_termino == 10) {
      mes_termino = "Octubre";
    } else if (mes_termino == 11) {
      mes_termino = "Noviembre";
    } else {
      mes_termino = "Diciembre";
    }

    if (mes_inicio == mes_termino) {
      if (dia_inicio == dia_termino) {
        return dia_inicio + " de " + mes_termino;
      } else {
        return dia_inicio + " al " + dia_termino + " de " + mes_termino;
      }
    } else {
      return (
        dia_inicio +
        " de " +
        mes_inicio +
        " al " +
        dia_termino +
        " de " +
        mes_termino
      );
    }
  }

  private formatFechaCard(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  private descripcionFechasEvento(fecha_inicio, fecha_termino) {
    var anho_inicio = fecha_inicio.substring(0, 4);
    var mes_inicio: any = parseInt(fecha_inicio.substring(5, 7), 10);
    var dia_inicio = fecha_inicio.substring(8, 10);
    var hora_inicio = fecha_inicio.substring(11, 16);

    var anho_termino = fecha_termino.substring(0, 4);
    var mes_termino: any = parseInt(fecha_termino.substring(5, 7), 10);
    var dia_termino = fecha_termino.substring(8, 10);
    var hora_termino = fecha_termino.substring(11, 16);

    if (mes_inicio == 1) {
      mes_inicio = "Enero";
    } else if (mes_inicio == 2) {
      mes_inicio = "Febrero";
    } else if (mes_inicio == 3) {
      mes_inicio = "Marzo";
    } else if (mes_inicio == 4) {
      mes_inicio = "Abril";
    } else if (mes_inicio == 5) {
      mes_inicio = "Mayo";
    } else if (mes_inicio == 6) {
      mes_inicio = "Junio";
    } else if (mes_inicio == 7) {
      mes_inicio = "Julio";
    } else if (mes_inicio == 8) {
      mes_inicio = "Agosto";
    } else if (mes_inicio == 9) {
      mes_inicio = "Septiembre";
    } else if (mes_inicio == 10) {
      mes_inicio = "Octubre";
    } else if (mes_inicio == 11) {
      mes_inicio = "Noviembre";
    } else {
      mes_inicio = "Diciembre";
    }
    if (mes_termino == 1) {
      mes_termino = "Enero";
    } else if (mes_termino == 2) {
      mes_termino = "Febrero";
    } else if (mes_termino == 3) {
      mes_termino = "Marzo";
    } else if (mes_termino == 4) {
      mes_termino = "Abril";
    } else if (mes_termino == 5) {
      mes_termino = "Mayo";
    } else if (mes_termino == 6) {
      mes_termino = "Junio";
    } else if (mes_termino == 7) {
      mes_termino = "Julio";
    } else if (mes_termino == 8) {
      mes_termino = "Agosto";
    } else if (mes_termino == 9) {
      mes_termino = "Septiembre";
    } else if (mes_termino == 10) {
      mes_termino = "Octubre";
    } else if (mes_termino == 11) {
      mes_termino = "Noviembre";
    } else {
      mes_termino = "Diciembre";
    }
    var response = "";
    if (mes_inicio == mes_termino) {
      if (dia_inicio == dia_termino) {
        response =
          dia_inicio +
          " de " +
          mes_termino +
          ": " +
          hora_inicio +
          " a las " +
          hora_termino;
      } else {
        response =
          dia_inicio +
          " al " +
          dia_termino +
          " de " +
          mes_termino +
          ": " +
          hora_inicio +
          " a las " +
          hora_termino;
      }
    } else {
      response =
        "Desde el " +
        dia_inicio +
        " de " +
        mes_inicio +
        " a las " +
        hora_inicio +
        " Hasta el " +
        dia_termino +
        " de " +
        mes_termino +
        " a las " +
        hora_termino;
    }
    return response;
  }

  private copiarClipboard() {
    copyToClipboard(window.location.href)
      .then(() => {
        Notify.create({
          color: "positive",
          message: "Se ha copiado en el Link correctamente.",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
        return;
      })
      .catch(() => {
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema. Por favor, inténtelo de nuevo.",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
        return;
      });
  }
  private irLink() {
    var link =
      "https://web.whatsapp.com/send?l=en&text=" +
      encodeURIComponent(window.location.href);
    window.open(link);
  }

  private irLinkMobile() {
    var link =
      "https://api.whatsapp.com/send?text=" +
      encodeURIComponent(window.location.href);
    window.open(link);
  }

  private irEmail(titulo: string, contenido: string) {
    window.open(
      "mailto:?Subject=" +
        titulo +
        "&" +
        "Body=" +
        encodeURIComponent(window.location.href)
    );
  }

  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Publicacion",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private irCategoriaActividad(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "CategoriaActividad" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "CategoriaActividad",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private irActividad(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "PublicacionAct" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      if (
        this.$router.currentRoute.name == "PublicacionAct" &&
        this.nombreWeb != nombreWeb
      ) {
        this.$router
          .push({
            name: "PublicacionAct",
            params: { nombre: nombreWeb },
          })
          .catch((err) => {
            console.log(err);
          });
        this.$router.go(0);
      } else {
        this.$router
          .push({
            name: "PublicacionAct",
            params: { nombre: nombreWeb },
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
}
